import React from "react"
import { Nav } from "@components"
import GlobalStyle from "@styles/global"

const Layout = ({ children, location }) => {
  // const isHome = location.pathname === "/"
  return (
    <div id="root">
      <GlobalStyle />
      <Nav />
      <div id="content">{children}</div>
    </div>
  )
}

export default Layout
