import React from "react";
import {
  IntroductionWrapper,
  SummaryContainer,
  SummaryRow,
  ProjectsContainer,
  SummaryHeader,
  CommitmentWrapper,
  Project,
  ProjectSummary,
  ProjectFooter,
  ProjectTitle,
  ProjectHeader,
  ProjectTechStackList,
  ProjectLink,
  ProjectTechStackItem,
} from "@styles/introduction";
import { SectionHeader } from "@styles/general";
import config from "@config";
import dayjs from "dayjs";
import Helmet from "react-helmet";

const Introduction = ({ projects, commitments }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Rohyl Joshi's personal website" />
        <title>Rohyl</title>
        <link rel="canonical" href="https://rohyl.io/" />
      </Helmet>
      <IntroductionWrapper>
        {/* <div>Hi, I'm a "full-stack" developer </div> */}
        <CommitmentWrapper>
          <SectionHeader>Recent Commitments</SectionHeader>
          <SummaryContainer>
            <thead>
              <SummaryHeader>
                <th>Role</th>
                <th>Place</th>
                <th>Duration</th>
              </SummaryHeader>
            </thead>
            <tbody>
              {commitments.map(
                ({ role, place, startDate, endDate, current }) => (
                  <SummaryRow key={role}>
                    <td>{role}</td>
                    <td>{place}</td>
                    <td>
                      {dayjs(startDate).format(config.commitmentDateFormat)} -{" "}
                      {current
                        ? "Current"
                        : dayjs(endDate).format(config.commitmentDateFormat)}
                    </td>
                  </SummaryRow>
                )
              )}
            </tbody>
          </SummaryContainer>
        </CommitmentWrapper>
        <SectionHeader>Projects</SectionHeader>
        <ProjectsContainer>
          {projects
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map(project => (
              <Project key={project.name}>
                <ProjectHeader>
                  <ProjectTitle>
                    {project.name}
                    <ProjectLink href={project.url}>view</ProjectLink>
                  </ProjectTitle>
                  <ProjectSummary>
                    {project.description.description}
                  </ProjectSummary>
                </ProjectHeader>
                <ProjectFooter>
                  <ProjectTechStackList>
                    {project.techStack.map(tech => (
                      <ProjectTechStackItem key={tech}>
                        {tech}
                      </ProjectTechStackItem>
                    ))}
                  </ProjectTechStackList>
                </ProjectFooter>
              </Project>
            ))}
        </ProjectsContainer>
      </IntroductionWrapper>
    </>
  );
};

export default Introduction;
