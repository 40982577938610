export default {
  linkHover: `
    text-shadow: 0px -0px 10px rgba(255, 255, 255, 0.25);
    &:hover {
      text-shadow: 0px -0px 10px rgba(255, 255, 255, 0.75);
    }`,
  flexRowCenter: `
    display: flex;
    flex-direction: row;
    align-items: center;`,

  flexColCenter: `
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    `,
}
