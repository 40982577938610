import { createGlobalStyle } from "styled-components";
export default createGlobalStyle`
    @font-face {
        font-family: "MADE Evolve Sans";
        src: url("/fonts/MADE Evolve Sans Medium.otf");
        font-weight: bold; 
    }
    
    @font-face {
        font-family: "MADE Evolve Sans";
        src: url("/fonts/MADE Evolve Sans Bold.otf");
        font-weight: bolder; 
    }

    @font-face {
        font-family: "MADE Evolve Sans";
        src: url("/fonts/MADE Evolve Sans Light.otf");
        font-weight: lighter; 
    }
    
    @font-face {
        font-family: "MADE Evolve Sans";
        src: url("/fonts/MADE Evolve Sans Regular.otf"); 
    } 

    @font-face {
        font-family: "MADE Evolve Sans EVO";
        src: url("/fonts/MADE Evolve Sans Regular EVO.otf");
        font-weight: normal; 
    }
    html {
        scroll-behavior: smooth;
    }
    html, body, #___gatsby, #___gatsby > *, #root {
        height: 100%;
    } 
    body {
        background-color: #0a192f;
        color: white;
        font-family: "MADE Evolve Sans", serif;
        font-weight: normal;
        margin: 0 auto;
    }
    /* span, p, div {
        cursor: default;
    } */
    #root {
        display: flex;
        flex-direction: column;
    } 
    #content {  
        /* margin: 20px 50px; */
        @media only screen and (max-width: 768px) {
            margin: 0px;
        }
        flex-grow: 1;
    }
`;
