const { FaBook, FaGithub, FaLinkedin } = require("react-icons/fa");
module.exports = {
  siteTitle: "Rohyl Joshi | Software Engineer",
  siteDescription:
    "Rohyl is a software engineer based in Australia who specializes in developing high-quality software applications, on the web and on the server.",
  siteKeywords:
    "Rohyl Joshi, Rohyl, Joshi, java, back-end engineer, software engineer, front-end engineer, web developer, javascript, melbourne, sydney",
  siteUrl: "https://rohyl.io/",
  siteLanguage: "en_AU",
  googleAnalyticsID: "UA-158075731-1",
  name: "Rohyl",
  location: "Melbourne, Australia",
  email: "rohyljoshi@hotmail.com",
  github: "https://github.com/Trontor",
  commitmentDateFormat: "MMM YYYY",

  navLinks: [
    {
      name: "Blog",
      url: "/blog",
      icon: FaBook,
    },
    {
      name: "GitHub",
      url: "https://github.com/Trontor",
      icon: FaGithub,
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/rohyl/",
      icon: FaLinkedin,
    },
  ],

  navHeight: 100,
  greenColor: "#64ffda",
  navyColor: "#0a192f",
  darkNavyColor: "#020c1b",
};
