import styled from "styled-components";
import { Link } from "gatsby";
import mixins from "./mixins";

export const NavContainer = styled.nav`
  ${mixins.flexRowCenter}
  justify-content: space-between;
  height: 70px;
  padding: 20px 40px;
  /* position: absolute; */
  width: calc(100% - 80px);
`;

export const NameContainer = styled.div``;
export const NameLink = styled(Link)`
  font-size: 2rem;
  color: inherit;
  text-decoration: none;
  font-weight: regular;
  letter-spacing: 0.25rem;
  cursor: pointer;
  text-transform: uppercase;
  transition-duration: 200ms;
  ${mixins.linkHover}
`;

export const LinkContainer = styled.div``;

export const NavLinkList = styled.ol`
  counter-reset: item;
  display: flex;
  flex-direction: row;
  list-style: none;
`;
export const NavLinkListItem = styled.li`
  @media only screen and (max-width: 500px) {
    display: none;
  }
  margin-right: 10px;
  cursor: pointer;
`;

export const NavLink = styled.a`
  color: inherit;
  padding: 10px 20px;
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: regular;
  ${mixins.linkHover}
`;

export const NavIconLink = styled.a`
  color: inherit;
  margin-right: 10px;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: regular;
  svg {
    :hover {
      filter: drop-shadow(0px -0px 10px rgba(255, 255, 255, 0.75));
    }
  }
`;

export const NavIconListItem = styled.li`
  @media only screen and (min-width: 500px) {
    display: none;
  }
`;
