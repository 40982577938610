import React from "react";
import config from "@config";
import {
  NavContainer,
  NameLink,
  NameContainer,
  LinkContainer,
  NavLinkList,
  NavIconLink,
  NavLinkListItem,
  NavLink,
  NavIconListItem,
} from "@styles/nav";

const Nav = () => {
  return (
    <NavContainer>
      <NameContainer>
        <NameLink to="/">{config.name}</NameLink>
      </NameContainer>
      <LinkContainer>
        <NavLinkList>
          {config.navLinks.map(({ name, url }, idx) => (
            <NavLinkListItem key={idx}>
              <NavLink href={url}>{name}</NavLink>
            </NavLinkListItem>
          ))}
          {config.navLinks.map(({ name, url, icon }, idx) => (
            <NavIconListItem key={idx}>
              <NavIconLink
                aria-label={name}
                href={url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {React.createElement(icon)}
              </NavIconLink>
            </NavIconListItem>
          ))}
        </NavLinkList>
      </LinkContainer>
    </NavContainer>
  );
};

export default Nav;
