import styled from "styled-components";
export const SectionHeader = styled.h1`
  text-align: center;
  font-size: 1.75em;
  margin: 25px 0 0 0;
  @media only screen and (max-width: 768px) {
    margin: 5px 0 0 0;
  }
`;
export const SectionSubHeader = styled.div`
  text-align: center;
  font-size: 0.5em;
  opacity: 0.5;
  margin: 5px 0 0 0;
`;
