import styled from "styled-components";
import mixins from "./mixins";

export const IntroductionWrapper = styled.main`
  ${mixins.flexColCenter}
  height: 100%;
  font-size: 1.5em;
  @media only screen and (max-width: 767px) {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: 376px) {
    font-size: 1rem;
  }
`;

export const SummaryContainer = styled.table`
  padding: 20px;
  min-width: 25%;
  max-width: 75%;
  margin-top: 10px;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    margin: 5px;
    max-width: 100%;
  }

  text-align: left;
  color: rgb(136, 146, 176);
  border-collapse: collapse;
  font-size: 0.8em;
`;

export const CommitmentWrapper = styled.section`
  display: contents;
`;

export const SummaryRow = styled.tr`
  td {
    padding: 15px 10px;
  }
  color: rgb(204, 214, 246);
`;

export const SummaryHeader = styled.tr`
  text-align: center;
  th {
    padding: 5px 0;
    font-weight: normal;
  }
  border-bottom: 2px solid rgba(100, 255, 218, 0.2);
`;

export const ProjectsContainer = styled.div`
  ${mixins.flexRowCenter}
  margin-top: 2em;
  @media only screen and (max-width: 768px) {
    ${mixins.flexColCenter}
    margin-top: 0.5em;
  }
`;

export const ProjectHeader = styled.div``;

export const Project = styled.div`
  box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  /* height: 100%; */
  background-color: rgb(23, 42, 69);
  padding: 2rem 1.75rem;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  max-width: 350px;
  margin-right: 20px;
  margin: 5px;
`;

export const ProjectTitle = styled.div`
  ${mixins.flexRowCenter}
  font-weight: normal;
  letter-spacing: 0.1em;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const ProjectLink = styled.a`
  font-size: 0.75em;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  cursor: pointer;
`;
export const ProjectSummary = styled.div`
  padding: 5px 0;
  font-weight: lighter;
  font-size: 0.75em;
`;
export const ProjectFooter = styled.footer``;
export const ProjectTechStackList = styled.ul`
  display: flex;
  align-items: flex-end;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0px;
  margin: 20px 0px 0px;
  font-size: 0.7em;
  list-style: none;
`;
export const ProjectTechStackItem = styled.li`
  margin-right: 15px;
  color: rgb(136, 146, 176);
`;
